import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Image from '~/components/Image';
import images from '~/assets/images';
import OpenAccount from '~/layouts/Components/OpenAccount';
import Identify from '~/layouts/Components/Identify';
import CardService from '~/components/CardService';
import CardCopyTrade from '~/components/CardCopyTrade';

const cx = classNames.bind(styles);

function Home() {
    const isSmallScreen = useMediaQuery({ maxWidth: 500 });

    const listImage = [
        images.ck2_1,
        images.ck2_2,
        images.ck2_3,
        images.ck2_4,
        images.ck2_5,
        images.ck2_6,
        images.ck2_7,
        images.ck2_8,
        images.ck2_9,
        images.ck2_10,
    ];

    const listService = [
        {
            header: 'Copy Trade',
            image: images.CopyTrader2,
            subHeader: '',
            description:
                'Nơi chúng tôi cho phép bạn sao chép toàn bộ giao dịch của chúng tôi. Với 8 năm kinh nghiệm trên thị trường, chúng tôi tự tin giúp các bạn giữ an toàn vốn và gia tăng lợi nhuận',
        },
        {
            header: 'Nhóm Tín Hiệu',
            colorHeader: '#ffcf01',
            image: images.TinHieu,
            description:
                'Nơi chúng tôi nhận định & đưa ra khuyến nghị giao dịch cho các trader vào lệnh. Cập nhật thường xuyên, liên tục và theo sát thị trường 24/7',
        },
    ];

    const listCopyTrade = [
        {
            header: 'Tiết kiệm thời gian & công sức',
            image: images.CopyTrader1,
            description:
                'Khi bạn chưa có nhiều kinh nghiệm và kiến thức trên thị trường. thì việc tìm được một Master sẽ giúp các bạn nhanh chóng bắt kịp thị trường, tiết kiệm thời gian và công sức một cách đáng kể. Master sẽ là  người giúp bạn đưa ra những quyết định chính xác, kịp thời đảm bảo an toàn vốn và gia tăng lợi nhuận.',
        },
        {
            header: 'Thu nhập thụ động',
            image: images.CopyTrader2,
            description:
                'Không cần phải ngồi nhìn Charp hàng ngày, hàng giờ, bạn vẫn có thể bắt kịp thị trường & đi lệnh một cách nhanh chóng, hiệu quả nhờ vào copy trade từ những Trader chuyên  nghiệp. Từ đó tạo ra thu nhập thụ động ngoài công việc chính hiện tại.',
        },
        {
            header: 'Cơ hội học hỏi',
            image: images.CopyTrader3,
            description:
                'Là những trader mới trên thị trường, việc tìm được 1 trader chuyên nghiệp là điều vô cùng hữu ích. giúp bạn học hỏi kinh nghiệm và trải nghiệm tuyệt vời. Nhìn cách đi lệnh và các công cụ tại mỗi thời điểm sẽ giúp bạn học hỏi được rất nhiều, từ đó tích lũy kiến thức cho bản thân. Theo thời gian và kinh nghiệm học hỏi bạn cũng sẽ là một Trader chuyên nghiệp trong tương lai.',
        },
    ];

    return (
        <div className={cx('wrapper')}>
            {/* Welcome */}
            <div className={cx('banner')}>
                <img src={images.BannerHome}/>
            </div>
            {/* <Banner img={images.bgExness} /> */}
            {/* Introduce */}
            <div className={cx('introduce-container')}>
                <div className={cx('introduce-header')}>Backcom Exness là gì? FreeSwap là gì?</div>
                <div className={cx('content-container')}>
                    <div className={cx('introduce-content')}>
                        <div className={cx('concept-container')}>
                            <Image src={images.IconTicked} className={cx('icon')}></Image>
                            <div className={cx('concept')}>
                                <span>Backcom Exness</span>, viết tắt của "Back Commission Exness", là chương trình
                                <span> hoàn trả phí giao dịch hàng ngày </span>
                                thông qua chương trình Bachcomfx dành riêng cho các Trader trên sàn Exness. Đây là
                                chương trình ưu đãi đặc biệt của Exness mang lại giá trị cho Trader giúp bạn tiết kiệm
                                chi phí giao dịch và tối ưu hóa lợi nhuận hàng ngày của mình. Chương trình ưu đãi hoàn
                                com <span>100%</span> lên tới <span>16$/lot</span> hàng ngày.
                                <ul>
                                    <li>- Backcom trên mọi nền tảng: MT4, MT5.</li>
                                    <li>
                                        - Backcom trên mọi loại tài khoản: standard plus, standard, zero, raw, pro,
                                        cent.
                                    </li>
                                    <li>- Backcom trên tất cả các cặp giao dịch mỗi ngày.</li>
                                </ul>
                            </div>
                        </div>
                        <div className={cx('concept-container')}>
                            <Image src={images.IconTicked} className={cx('icon')}></Image>
                            <div className={cx('concept')}>
                                Phí <span>swap</span> là lãi suất qua đêm được cộng hoặc trừ vào tài khoản khách hàng
                                khi giữ lệnh qua đêm khoản phí này tùy thuộc vào từng sàn và từng loại tài khoản( XM
                                đang thu 37,68$/lot Xauusd). <span>Freeswap Exness</span> là chương trình{' '}
                                <span>miễn phí giữ lệnh qua đêm</span> cho Trader trên sàn Exness với mọi tài khoản được
                                lập mới qua chương trình freeswap
                            </div>
                        </div>
                    </div>
                    <div className={cx('image-container')}>
                        <div className={cx('header')}>
                            <p>Mức Hoàn Tiền của các loại tài khoản (USD/lot)</p>
                        </div>
                        <img className={cx('img')} alt="" src={images.TableRefund} />
                    </div>
                </div>
            </div>
            {/* WhyChoice */}
            <div className={cx('why-choice-container')}>
                <div className={cx('why-choice-header')}>Tại sao phải nhận Backcom?</div>
                <div className={cx('content-container')}>
                    <div className={cx('why-choice-content')}>
                        <div className={cx('concept-container')}>
                            <Image src={images.IconTicked} className={cx('icon')}></Image>
                            <div className={cx('concept')}>
                                Khi tạo tài khoản theo cách thông thường, Exness sẽ thu phí spread và swap của bạn với
                                mọi giao dịch. Khoản phí này tuy không lớn nhưng cũng không nhỏ nếu bạn là một trader
                                chuyên nghiệp và giao dịch trong thời gian dài.
                            </div>
                        </div>
                        <div className={cx('concept-container')}>
                            <Image src={images.IconTicked} className={cx('icon')}></Image>
                            <div className={cx('concept')}>
                                Khi bạn tạo tài khoản thông qua Chương Trình ưu đãi đặc biệt Backcomfx của Exness, bạn
                                sẽ được miễn phí qua đêm, nhận Backcom 100% số tiền phí giao dịch tự động vào 12-16h
                                hàng ngày .
                            </div>
                        </div>
                        <div className={cx('concept-container')}>
                            <Image src={images.IconTicked} className={cx('icon')}></Image>
                            <div className={cx('concept')}>
                                Tạo tài khoản qua Chương Trình hỗ trợ Backcomfx không ảnh hưởng đến hoạt động giao dịch
                                của bạn, không tăng spread mà giúp bạn nhận lại 100% phí giao dịch lên tới 16$/lot và
                                miễn phí giao dịch qua đêm . Vậy còn lí do gì mà bạn chưa đăng ký nhận Backcom ngay.
                            </div>
                        </div>
                    </div>
                    <div className={cx('image-container')}>
                        {/* <div className={cx('header')}>
                            <p>Mức Hoàn Tiền của các loại tài khoản (USD/lot)</p>
                        </div> */}
                        {isSmallScreen ? (
                            <Swiper
                                style={{
                                    '--swiper-navigation-color': '#ffbf00',
                                    '--swiper-pagination-color': '#ffbf00',
                                    '--swiper-pagination-bullet-inactive-color': '#ffbf00',
                                    '--swiper-pagination-bullet-inactive-opacity': '0.3',
                                }}
                                slidesPerView={1}
                                spaceBetween={10}
                                pagination={{
                                    clickable: true,
                                }}
                                // loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                modules={[Pagination, Autoplay]}
                                className={cx('mySwiper', 'swiper')}
                            >
                                {listImage.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <img alt="" src={item} loading="lazy" />
                                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : (
                            <img className={cx('img')} alt="" src={images.MobileWhy} />
                        )}
                    </div>
                </div>
            </div>
            {/* Registration instruction */}
            <Element name="scrollTarget" className="scrollTarget">
                <div className={cx('registration-instruction-container')}>
                    <div className={cx('registration-header')}>
                        {isSmallScreen ? (
                            <>
                                Cách đăng ký <br></br> Backcom Exness
                            </>
                        ) : (
                            <>Cách đăng ký Backcom Exness</>
                        )}
                    </div>
                    <div className={cx('registration-content-container')}>
                        <div className={cx('registration-content')}>
                            <div className={cx('registration-way')}>
                                <b>Cách 1</b>: Mở tài khoản Exness mới
                            </div>
                            <div className={cx('content-container')}>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B1</b>: Tạo tài khoản mới tại trang chủ Exness hoặc
                                        <a
                                            target="blank"
                                            href="https://one.exnesstrack.net/boarding/sign-up/a/backcomfx?lng=vi"
                                        >
                                            tại đây
                                        </a>
                                    </p>
                                    <ul>
                                        <li>- Một số CCCD, SĐT có thể tạo nhiều tài khoản</li>
                                        <li>- Phải sử dụng Email mới chưa được đăng ký tài khoản Exness</li>
                                    </ul>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B2</b>: Tại mục mã đối tác bạn nhập:
                                    </p>
                                    <div>
                                        <p>
                                            backcomfx <i>(để nhận backcom 95%)</i>
                                        </p>
                                        <p>
                                            freeswap <i>(để nhận backcom 95% + miễn phí giao dịch qua đêm)</i>
                                        </p>
                                        <img src={images.codeFreeSwap} alt="" />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B3</b>: Quay lại trang ThoTrading.com Nhắn tin cho support qua messenger hoặc
                                        telegram để được xác nhận và hoàn com ngay
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={cx('registration-content')}>
                            <div className={cx('registration-way')}>
                                <b>Cách 2</b>: Đăng ký backcom đối với người đã có tài khoản Exness.
                            </div>
                            <div className={cx('content-container')}>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B1</b>: Đăng nhập tài khoản Exness của bạn và nhắn support Exness với nội
                                        dung <br /> "Thay đổi đối tác"
                                    </p>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B2</b>: Nhấn vào đường link mà Exness gửi bạn để đến bảng thay đổi đối tác.
                                    </p>
                                    <Image src={images.changePartner1} />
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B3</b>: Tại phần lý do thay đổi đối tác bạn chọn: Chiết khấu.
                                    </p>
                                    <p className={cx('sub-title')}>
                                        Tại phần nhập số tài khoản đối tác backcomfx bạn chọn:
                                    </p>
                                    <div>
                                        <p>
                                            <a target="blank" href="https://one.exnesstrack.net/intl/vi/a/backcomfx">
                                                https://one.exnesstrack.net/intl/vi/a/backcomfx
                                            </a>{' '}
                                            <i>(để nhận backcom)</i>
                                        </p>
                                        <p>
                                            <a
                                                target="blank"
                                                href="https://one.exnesstrack.net/boarding/sign-up/a/freeswap"
                                            >
                                                https://one.exnesstrack.net/boarding/sign-up/a/freeswap
                                            </a>{' '}
                                            <i>(để nhận backcom & freeswap)</i>
                                        </p>
                                        <Image src={images.changePartner2} alt="" />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B4</b>: Nhập mã xác minh được gửi về điện thoại của bạn.
                                    </p>
                                    <p className={cx('sub-title')}>
                                        Nhấn "Xác Nhận" để hoàn thành thay đổi sang đối tác backcomfx
                                    </p>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B5</b>: Sau khi hoàn tất quá trình chuyển đổi đối tác, Exness sẽ gửi Email
                                        xác nhận đến bạn.
                                    </p>
                                </div>
                                <div className={cx('content')}>
                                    <p>
                                        <b>B5</b>: Nhắn tin cho tôi Email tài khoản Exness
                                    </p>
                                </div>

                                <div className={cx('content')}>
                                    <p>
                                        <b>B6</b>: Mở tài khoản MT4/MT5 mới giao dịch và nhận backcom tự động về tài
                                        khoản hằng ngày.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={cx('note')}>
                            * Lưu ý: Nếu không được duyệt theo cách 2, các bạn hãy làm theo cách 1 {'(được duyệt 100%)'}
                        </div>
                    </div>
                </div>
            </Element>
            {/* Services */}
            <div className={cx('service-container')}>
                <div className={cx('service-header')}>Dịch Vụ Khác Của Chúng Tôi</div>
                <div className={cx('content-container')}>
                    {listService.map((item, index) => {
                        return <CardService key={index} {...item} />;
                    })}
                </div>
            </div>

            {/* Copy Trade */}
            <div className={cx('copy-trade-container')}>
                <div className={cx('copy-trade-header')}>Copy Trade</div>
                <div className={cx('intro-container')}>
                    <p>
                        Hiện tại, copy trade có nhiều chế độ khác nhau, nhưng mỗi sàn giao dịch hiện chỉ sử dụng một số
                        chế độ mà không hỗ trợ toàn bộ chức năng cho copy trade. Một số hình thức của copy trade điển
                        hình như:
                    </p>
                    <ul>
                        <li>
                            - Sao chép toàn bộ từ đòn bẩy cho tới số vốn đầu tư: Ví dụ, lead trader bỏ vào 100 USD với
                            đòn bẩy x10, người dùng cũng sao chép y hệt toàn bộ. Nếu như số tiền của lead trader cao
                            hơn, người dùng sẽ không thể sao chép giao dịch.
                        </li>
                        <li>
                            - Sao chép giao dịch với tỷ lệ vốn nhất định: Ví dụ, lead trader thực hiện lệnh với kích
                            thước 100 USD và người dùng đặt mức tỷ lệ 10% thì sao chép lệnh nhưng với kích thước 10 USD.
                        </li>
                        <li>
                            - Tự điều chỉnh mức kích thước cố định: Ví dụ, người dùng đặt mức 100 USDT, thì mỗi lệnh sao
                            chép chỉ có kích thước tối đa là 100 USDT.
                        </li>
                    </ul>
                </div>
                <div className={cx('content-container')}>
                    {listCopyTrade.map((item, index) => {
                        return <CardCopyTrade key={index} {...item} />;
                    })}
                </div>
                {/* <div className={cx('link')}>
                    Đăng kí copy trade{' '}
                    <a href="https://youtube.com" target="blank">
                        tại đây !
                    </a>
                </div> */}
            </div>

            {/* Banner */}
            <div className={cx('banner-container')}>
                <img className={cx('banner')} src={images.Banner} />
                <div className={cx('link-container')}>
                    <div className={cx('link')}>
                        Tham gia nhóm tín hiệu{' '}
                        <a href="https://t.me/backcomexnessvip" target="blank">
                            tại đây !
                        </a>
                    </div>
                    <div className={cx('link')}>
                        Đăng kí copy trade{' '}
                        <a href="https://t.me/backcomexnessvip" target="blank">
                            tại đây !
                        </a>
                    </div>
                    <div className={cx('link')}>
                        Đăng kí nhận Backcom{' '}
                        <a href="https://t.me/backcomexnessvip" target="blank">
                            tại đây !
                        </a>
                    </div>
                </div>
            </div>

            {/* Start open account */}
            <OpenAccount />
            {/* Identify */}
            <Identify />

            {/* <div style={{ height: '1000px' }}></div> */}
        </div>
    );
}

export default Home;
