import classNames from 'classnames/bind';
import styles from './Identify.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import images from '~/assets/images';

const cx = classNames.bind(styles);

function Identify() {
    const isSmallScreen = useMediaQuery({ maxWidth: 820 });
    const listImage = [images.IMG6671, images.IMG6612, images.IMG6611, images.IMG6610, images.IMG6609, images.IMG6608];

    return (
        <div className={cx('identify-container', 'no-select')}>
            <div className={cx('identify-header')}>
                {isSmallScreen ? (
                    <>
                        Nhận định giá vàng độc quyền từ Backcom Exness
                        <a target="blank" href="https://www.facebook.com/backcomexness.net">
                            tại đây
                        </a>{' '}
                        <br />
                        Tỷ lệ chính xác trên 80%
                    </>
                ) : (
                    <>
                        Nhận định giá vàng độc quyền <br />
                        từ ThoTrading
                        <a target="blank" href="https://m.me/tansunshinegroup">
                            tại đây
                        </a>{' '}
                        <br />
                        Tỷ lệ chính xác trên 80%
                    </>
                )}
            </div>
            <div className={cx('content-container')}>
                <Swiper
                    style={{
                        '--swiper-navigation-color': '#ffbf00',
                        '--swiper-pagination-color': '#ffbf00',
                        '--swiper-pagination-bullet-inactive-color': '#ffbf00',
                        '--swiper-pagination-bullet-inactive-opacity': '0.3',
                    }}
                    slidesPerView={1}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    // loop={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        480: {
                            slidesPerView: 1,
                            // spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                        },
                        1500: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                        },
                    }}
                    modules={[Pagination, Autoplay]}
                    className={cx('mySwiper', 'swiper')}
                >
                    {listImage.map((item, index) => (
                        <SwiperSlide key={index}>
                            <img alt="" src={item} loading="lazy" />
                            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

Identify.prototype = {};

export default Identify;
