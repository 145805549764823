import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import { Link, useLocation } from 'react-router-dom';

import ExnessLogoWhite from '~/assets/images/ExnessLogoWhite.svg';
import LogoPeace from '~/assets/images/LogoPeace.png';
import LogoXM from '~/assets/images/XMLogo-2021_homepage.svg';
import LogoBinance from '~/assets/images/LogoBinance.svg';

import Image from '~/components/Image';
import config from '~/config';
import ButtonLink from '~/components/ButtonLink';

const cx = classNames.bind(styles);

function Header() {
    const location = useLocation();
    function locationPage() {
        const path = location.pathname.toLowerCase();

        if (path.includes('xm')) {
            return 'XM';
        } else if (path.includes('binance')) {
            return 'Binance';
        } else {
            return 'Exness';
        }
    }

    return (
        <header className={cx('wrapper')}>
            <div className={cx('inner')}>
                {/* {LOGO} */}
                <div className={cx('container-logo')}>
                    <Link to={config.routes.home}>
                        <Image className={cx('logo-exness')} src={ExnessLogoWhite} />
                        <Image className={cx('logo-peace')} src={LogoPeace} />
                    </Link>
                    <Link to={config.routes.xm}>
                        <Image className={cx('logo-xm', 'big-logo')} src={LogoXM} />
                    </Link>
                    <Link to={config.routes.binance}>
                        <Image className={cx('logo-binance', 'big-logo')} src={LogoBinance} />
                    </Link>
                </div>
                <ButtonLink className={cx('btn-open-account')} to={"scrollTarget"}>Nhận Backcom {locationPage()}</ButtonLink>
            </div>
            <div className={cx('container-balloon')}>
                <div
                    className={cx('messenger-balloon')}
                    onClick={() => window.open('https://www.facebook.com/backcomexness.net', '_blank')}
                />
                <div
                    className={cx('telegram-balloon')}
                    onClick={() => window.open('http://t.me/backcomexnessvip', '_blank')}
                />
                <div
                    className={cx('zalo-balloon')}
                    onClick={() => window.open('https://zalo.me/0946262129', '_blank')}
                />
            </div>
        </header>
    );
}

export default Header;
