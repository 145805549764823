import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './CardService.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function CardService({
    header,
    colorHeader = "#fff", 
    image,
    description,
    colorDescription = "#fff", 
}) {
    return (
        <div className={cx('container')}>
            <div className={cx('header')} style={{color:colorHeader}}>{header}</div>
            <div className={cx('image')}>
                <img src={image} alt={header} />
            </div>
            <div className={cx('description')} style={{color:colorDescription}}>{description}</div>
        </div>
    );
}

CardService.propTypes = {
   
};

export default CardService;
