import config from '~/config';

import Home from '~/pages/Home/Home';
import XM from '~/pages/XM/XM';
import Binance from '~/pages/Binance/Binance';

const publicRoutes = [
    { path: config.routes.home, component: Home },
    { path: config.routes.xm, component: XM },
    { path: config.routes.binance, component: Binance },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
