import classNames from 'classnames/bind';
import styles from './OpenAccount.module.scss';
import { useMediaQuery } from 'react-responsive';


import ButtonLink from '~/components/ButtonLink';

const cx = classNames.bind(styles);

function OpenAccount() {
    const isSmallScreen = useMediaQuery({ maxWidth: 820 });

    return (
        <div className={cx('start-container')}>
            <div className={cx('content')}>
                <h2 className={cx('content-header')}>
                    Bạn đã sẵn sàng bắt đầu <br />
                    chưa?
                </h2>
            </div>
            <ButtonLink to={'scrollTarget'}>Đăng kí nhận Backcom</ButtonLink>
            <i className={cx('content-text')}>
                {isSmallScreen ? (
                    <>
                        Đây không phải là lời khuyên đầu tư.
                        <br /> Hãy luôn thận trọng trong mọi quyết định giao dịch. Quản trị rủi do và cảm xúc là chìa
                        khóa cho mọi thành công.
                    </>
                ) : (
                    <>
                        Đây không phải là lời khuyên đầu tư. Hãy luôn thận trọng trong mọi quyết định giao dịch. <br />
                        Quản trị rủi do và cảm xúc là chìa khóa cho mọi thành công.
                    </>
                )}
            </i>
        </div>
    );
}

OpenAccount.prototype = {};

export default OpenAccount;
