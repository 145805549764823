const images = {
    logo: require('~/assets/images/logo.svg').default,
    noImage: require('~/assets/images/no-image.jfif').default,
    logoWhite: require('~/assets/images/ExnessLogoWhite.svg').default,
    
    logoTho : require('~/assets/images/logoTho.png'),
    logoPeace : require('~/assets/images/LogoPeace.png'),
    logoXM : require('~/assets/images/XMLogo-2021_homepage.svg'),
    logoBinance : require('~/assets/images/LogoBinance.svg'),
    ck1 : require('~/assets/images/ck1.jpg'),
    ck2 : require('~/assets/images/ck2.jpg'),
    ck3 : require('~/assets/images/ck3.jpg'),
    ck4 : require('~/assets/images/ck4.jpg'),
    ck5 : require('~/assets/images/ck5.jpg'),

    bgXM : require('~/assets/images/backgr-XM.png'),
    bgBinance : require('~/assets/images/backgr-binance.jpg'),
    bgExness : require('~/assets/images/backgrExness.jpg'),
    bgExnessMobile : require('~/assets/images/backgr-moblie.jpg'),

    
    codeFreeSwap : require('~/assets/images/codeFreeSwap.jpg'),
    changePartner1 : require('~/assets/images/changePartner1.jpg'),
    changePartner2 : require('~/assets/images/changePartner2.jpg'),
    IMG6608 : require('~/assets/images/IMG6608.png'),
    IMG6609 : require('~/assets/images/IMG6609.png'),
    IMG6610 : require('~/assets/images/IMG6610.png'),
    IMG6611 : require('~/assets/images/IMG6611.png'),
    IMG6612 : require('~/assets/images/IMG6612.png'),
    IMG6671 : require('~/assets/images/IMG6671.png'),

    logoZalo : require('~/assets/images/IconZalo.png'),
    logoMessenger : require('~/assets/images/IconMesseger.png'),
    logoTelegram : require('~/assets/images/IconTelegram.png'),

    IconTicked : require('~/assets/images/IconTicked.png'),
    IconYoutube : require('~/assets/images/youtube-logo.svg'),
    IconLinkedin : require('~/assets/images/linkedIn-logo.svg'),
    IconTelegram : require('~/assets/images/telegram-logo.svg'),
    IconFacebook : require('~/assets/images/facebook-logo.svg'),

    //XM
    XmRegister : require('~/assets/images/XmRegister.jpg'),
    XmRegister2 : require('~/assets/images/XmRegister2.jpg'),
    XmType : require('~/assets/images/XmType.jpg'),
    XmActive1 : require('~/assets/images/XmActive1.jpg'),
    XmActive2 : require('~/assets/images/XmActive2.jpg'),

    // Binance
    BinanceSignup : require('~/assets/images/BinanceSignup.jpg'),
    BinanceSignin : require('~/assets/images/BinanceSignin.jpg'),
    BinanceSignin2 : require('~/assets/images/BinanceSignin2.jpg'),
    BinanceSetUp : require('~/assets/images/BinanceSetUp.jpg'),
    BinanceSecurity1 : require('~/assets/images/BinanceSecurity1.jpg'),
    BinanceSecurity2 : require('~/assets/images/BinanceSecurit2.jpg'),
    BinanceRemove : require('~/assets/images/BinanceRemove.jpg'),
    BinanceReason : require('~/assets/images/BinanceReason.jpg'),

    ck2_1 : require('~/assets/images/ck2.1.jpg'),
    ck2_2 : require('~/assets/images/ck2.2.jpg'),
    ck2_3 : require('~/assets/images/ck2.3.jpg'),
    ck2_4 : require('~/assets/images/ck2.4.jpg'),
    ck2_5 : require('~/assets/images/ck2.5.jpg'),
    ck2_6 : require('~/assets/images/ck2.6.jpg'),
    ck2_7 : require('~/assets/images/ck2.7.jpg'),
    ck2_8 : require('~/assets/images/ck2.8.jpg'),
    ck2_9 : require('~/assets/images/ck2.9.jpg'),
    ck2_10 : require('~/assets/images/ck2.10.jpg'),

    TableRefund : require('~/assets/images/TableRefund.png'),
    MobileWhy : require('~/assets/images/MoblieWhy.png'),
    CopyTrader1 : require('~/assets/images/CopyTrader1.png'),
    CopyTrader2 : require('~/assets/images/CopyTrader2.png'),
    CopyTrader3 : require('~/assets/images/CopyTrade3.png'),
    TinHieu : require('~/assets/images/TinHieu.png'),
    Banner : require('~/assets/images/Banner.png'),
    BannerHome : require('~/assets/images/BannerHome.png'),

    
}

export default images