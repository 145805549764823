import classNames from 'classnames/bind';
import styles from './Binance.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll';

import images from '~/assets/images';
import Banner from '~/layouts/Components/Banner';
import Identify from '~/layouts/Components/Identify';
import OpenAccount from '~/layouts/Components/OpenAccount';

const cx = classNames.bind(styles);

function Binance() {
    return (
        <div className={cx('wrapper')}>
            {/* Welcome */}
            <Banner img={images.bgBinance} />

            {/* Registration instruction */}
            <Element name="scrollTarget" className="scrollTarget">
                <div className={cx('registration-instruction-container')}>
                    <div className={cx('registration-header')}>Nhận Backcom Binance</div>

                    <div className={cx('registration-content-container')}>
                        <div className={cx('registration-description')}>
                            <b>
                                Backcom Binance là chương trình hoàn trả hoa hồng độc quyền dành cho các nhà đầu tư mới
                                trên Binance. Chương trình này được triển khai bởi các IB (nhà mô giới) thuộc nhóm
                                backcom của Binance, mang đến phí giao dịch siêu thấp cho người dùng lần đầu mở tài
                                khoản
                            </b>
                        </div>
                        <div className={cx('registration-content')}>
                            <div className={cx('registration-way')}>
                                <b>Cách 1</b>: Nhận Backcom Binance đối với tài khoản mới
                            </div>
                            <div className={cx('content-container')}>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B1</strong>: Mở tài khoản Binance nhận Backcom{' '}
                                        <a target='blank' href="https://one.exnesstrack.net/boarding/sign-up/a/freeswap">
                                            tại đây
                                        </a>
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <img className={cx('image-100')} src={images.BinanceSignup} />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B2</strong>: Điền đầy đủ thông tin cá nhân của bạn và xác minh tài khoản
                                        theo yêu cầu
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <img className={cx('image-70')} src={images.BinanceSetUp} />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B3</strong>: Giao dịch nhận Backcom tự động mỗi giao dịch
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>
                                            Ngoài ra Thotrading.com còn hỗ trợ Backcom tự động Exness, XM. Xem thêm{' '}
                                            <a target="blank" href="https://Thotrading.com/">
                                                tại đây
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={cx('registration-way')}>
                                <b>Cách 2</b>: Đối với người đang có tài khoản Binance. Xoá tài khoản hiện tại, tạo lại
                                tài khoản Binance mới
                            </div>
                            <div className={cx('content-container')}>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B1</strong>: Đăng nhập vào app Binance
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>Đăng nhập vào tài khoản Binance của bạn bằng email và mật khẩu đã đăng ký</p>
                                        <p>Nhấn vào biểu tượng Hồ sơ ở góc bên trái màn hình</p>
                                        <div className={cx('container-image')}>
                                            <img className={cx('flex-5')} src={images.BinanceSignin} />
                                            <img className={cx('flex-3')} src={images.BinanceSignin2} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B2</strong>: Mở tab Bảo mật
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>Trong trang Cài đặt, nhấn vào tab Bảo mật</p>
                                        <p>Cuộn xuống phần quản lý tài khoản</p>
                                        <div className={cx('container-image')}>
                                            <img className={cx('image-40')} src={images.BinanceSecurity1} />
                                            <img className={cx('image-30')} src={images.BinanceSecurity2} />
                                        </div>
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B3</strong>: Nhấn vào nút "Xoá tài khoản"
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <img className={cx('image-sm')} src={images.BinanceRemove} />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B4</strong>: Xác nhận xoá tài khoản
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>
                                            Một của sổ xác nhận sẽ xuất hiện, yêu cầu bạn nhập mật khẩu và lý do xoá tài
                                            khoản
                                        </p>
                                        <p>Một email xác nhận sẽ được gửi đến địa chỉ email đã đăng ký của bạn</p>
                                        <p>Nhấn vài liên kết trong email xác nhận để hoàn tất việc xoá tài khoản</p>
                                        <img className={cx('image-sm')} src={images.BinanceReason} />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B5</strong>: Tạo tài khoản mới
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>
                                            Mở tài khoản Binance mới như cách 1 bằng thông tin email, số điện thoại và
                                            CCCD của tài khoản vừa xoá để nhận Backcom tự động hằng ngày
                                        </p>
                                    </div>
                                </div>
                                <div className={cx('note')}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            {/* Start open account */}
            <OpenAccount />
            {/* Identify */}
            <Identify />
        </div>
    );
}

export default Binance;
