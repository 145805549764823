import classNames from 'classnames/bind';
import styles from './Footer.module.scss';

import ExnessLogoBlack from '~/assets/images/ExnessLogoBlack.svg';
import LogoPeace from '~/assets/images/LogoPeace.png';
import Image from '~/components/Image';
import logoYTB from '~/assets/images/youtube-logo.svg';
import logoFB from '~/assets/images/facebook-logo.svg';
import logoTL from '~/assets/images/telegram-logo.svg';
import logoIN from '~/assets/images/linkedIn-logo.svg';

const cx = classNames.bind(styles);

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <div className={cx('wrapper')}>
            <div className={cx('line')}></div>
            <div className={cx('content')}>
                <div className={cx('container-logo-icon')}>
                    <div className={cx('logo')}>
                        <Image className={cx('logo-exness')} src={ExnessLogoBlack} />
                        <Image className={cx('logo-peace')} src={LogoPeace} />
                    </div>
                    <div className={cx('icon')}>
                        <a href="https://www.facebook.com/tansunshinegroup" target="blank">
                            <Image className={cx('logo-facebook')} src={logoFB} />
                        </a>
                        <a href="http://t.me/backcomexnessvip" target="blank">
                            <Image className={cx('logo-telegram')} src={logoTL} />
                        </a>
                        <a href="https://www.youtube.com" target="blank">
                            <Image className={cx('logo-youtube')} src={logoYTB} />
                        </a>
                        <a href="https://www.linkedin.com" target="blank">
                            <Image className={cx('logo-linkedIn')} src={logoIN} />
                        </a>
                    </div>
                </div>
                <div className={cx('copyright')}>© {currentYear} Backcomexness</div>
            </div>
        </div>
    );
}

export default Footer;
