import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './CardCopyTrade.module.scss';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function CardCopyTrade({
    header,
    colorHeader = "#ffcf01", 
    image,
    description,
    colorDescription = "#fff", 
}) {
    return (
        <div className={cx('container')}>
            <div className={cx('image')}>
                <img src={image} alt={header} />
            </div>
            <div className={cx('header')} style={{color:colorHeader}}>{header}</div>
            <div className={cx('description')} style={{color:colorDescription}}>{description}</div>
        </div>
    );
}

CardCopyTrade.propTypes = {
   
};

export default CardCopyTrade;
