import classNames from 'classnames/bind';
import styles from './XM.module.scss';
import { useMediaQuery } from 'react-responsive';
import { Element } from 'react-scroll';

import images from '~/assets/images';
import Banner from '~/layouts/Components/Banner';
import Identify from '~/layouts/Components/Identify';
import OpenAccount from '~/layouts/Components/OpenAccount';
import Image from '~/components/Image';

const cx = classNames.bind(styles);

function XM() {
    return (
        <div className={cx('wrapper')}>
            {/* Welcome */}
            <Banner img={images.bgXM} />
            {/* Registration instruction */}
            <Element name="scrollTarget" className="scrollTarget">
                <div className={cx('registration-instruction-container')}>
                    <div className={cx('registration-header')}>
                        Nhận Backcom <i>X</i>
                        <b>M</b>
                    </div>

                    <div className={cx('registration-content-container')}>
                        <div className={cx('registration-description')}>
                            <p>
                                Backcom <span className={cx('title-page-x')}>X</span>
                                <span className={cx('title-page-m')}>M</span> là chương trình hoàn trả hoa hồng giao
                                dịch được cung cấp bởi sàn <span className={cx('title-page-x')}>X</span>
                                <span className={cx('title-page-m')}>M</span> dànhcho các nhà giao dịch. Tham gia chương
                                trình này, bạn có thể nhận lại lên đến 100% phí giao dịch đã thanh toán, giúp tăng lợi
                                nhuận và giảm chi phí để giao dịch đánh kể
                            </p>
                        </div>
                        <div className={cx('registration-content')}>
                            <div className={cx('content-container')}>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B1</strong>: Mở tài khoản:
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>
                                            Truy cập website chính thức của XM theo link:{' '}
                                            <a target="blank" href="http://bit.ly/XMbonus2024">
                                                http://bit.ly/XMbonus2024
                                            </a>
                                        </p>
                                        <p>
                                            Nhấn vào nút "Đăng ký" và điền đầy đủ thông tin cá nhân. Nhập mã đối tác:{' '}
                                            <b>3C2RR</b>
                                        </p>
                                        <p>Xác minh email và số điện thoại của bạn</p>
                                        <Image src={images.XmRegister} />
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B2</strong>: Liên hệ với tôi (Giới thiệu viên):
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>Nhắn tin cho tôi số tài khoản XM của bạn qua Telegram, Facebook</p>
                                        <p>
                                            Tôi sẽ cài đặt backcom tự động cho tài khoản của bạn đồng thời add bạn vào
                                            nhóm tín hiệu VIP nhận thông tin giao dịch hàng ngày miễn phí với tỉ lệ
                                            chính xác trên 90%.
                                        </p>
                                    </div>
                                </div>
                                <div className={cx('content')}>
                                    <b>
                                        <strong>B3</strong>: Giao dịch và nhận hoàn phí:
                                    </b>
                                    <div className={cx('sub-title-way')}>
                                        <p>Bắt đầu giao dịch trên tài khoản XM của bạn</p>
                                        <p>Hoa hồng giao dịch sẽ được tự động hoàn vào tài khoản của bạn mỗi ngày</p>
                                        <p>
                                            Bạn có thể theo dõi tiền backcom trên MT4/MT5 hoặc lịch sử giao dịch tài
                                            khoản XM
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Element>
            {/* Bonus */}
            <div className={cx('registration-instruction-container')}>
                <div className={cx('registration-header')}>
                    Nhận Bonus <i>X</i>
                    <b>M</b>
                </div>

                <div className={cx('registration-content-container')}>
                    <div className={cx('registration-description')}>
                        <p>
                            Nhận Bonus 100% nạp tiền <span className={cx('title-page-x')}>X</span>
                            <span className={cx('title-page-m')}>M</span> lên đến 500$ khi bạn nạp tiền vào tài khoản{' '}
                            <span className={cx('title-page-x')}>X</span>
                            <span className={cx('title-page-m')}>M</span> Standard hoặc Micro của bạn trong thời gian
                            khuyến mại. Chương trình khuyến mãi áp dụng liên tục mỗi tháng. % Bonus thay đổi tuỳ từng
                            tháng và phụ thuộc vào số tiền bạn nạp và áp dụng cho lần nạp đầu tiên trong thời gian
                            khuyến mại.
                        </p>
                    </div>
                    <div className={cx('registration-content')}>
                        <div className={cx('content-container')}>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B1</strong>: Truy cập trang web XM theo đường link:{' '}
                                    <a target="blank" href="http://bit.ly/XMbonus2024">
                                        http://bit.ly/XMbonus2024
                                    </a>
                                </b>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B2</strong>: Đăng ký tài khoản:
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>
                                        Nhập mã đối tác nhận bonus 100% tiền nạp lần đầu: <b>3C2RR</b>
                                    </p>
                                    <Image src={images.XmRegister2} />
                                </div>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B3</strong>: Xác minh tài khoản XM
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>
                                        XM sẽ gửi email xác minh đến địa chỉ email của bạn đã đăng ký. Bạn cần truy cập
                                        email và nhấn vào liên kết xác minh để kích hoạt tài khoản của mình.
                                    </p>
                                </div>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B4</strong>: Chọn loại tài khoản
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>XM cung cấp nhiều loại tài khoản khác nhau để bạn lựa chọn, bao gồm:</p>
                                    <ul>
                                        <li>
                                            - Tài khoản Micro: Phù hợp cho người mới bắt đầu với số tiền nạp tối thiểu
                                            thấp
                                        </li>
                                        <li>
                                            - Tài khoản Standard: Phù hợp cho nhà đầu tư có kinh nghiệm với mức spread
                                            thấp
                                        </li>
                                        <li>
                                            - Tài khoản Ultra Low: Phù hợp cho nhà đầu tư chuyên nghiệp với mứa spread
                                            siêu thấp
                                        </li>
                                    </ul>
                                    <Image src={images.XmType} />
                                </div>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B5</strong>: Kích hoạt Bonus
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>Sau khi nạp tiền thành công, bạn cần kích hoạt bonus để nhận tiền thưởng.</p>
                                    <p>Bạn có thể thực hiện theo các bước sau:</p>
                                    <ul>
                                        <li>- Truy cập vào khu vực thành viên của XM</li>
                                        <li>- Chọn loại "Bonus" bạn muốn sử dụng</li>
                                        <li>- Cả 2 loại bonus 50% và 20% đều là bonus chịu giá</li>
                                        <li>- Có 2 loại bonus XM khi nạp tiền</li>
                                    </ul>
                                    <Image src={images.XmActive2} />
                                    <br />
                                    <Image src={images.XmActive1} />
                                </div>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B6</strong>: Nạp tiền vào tài khoản
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>Bạn cần nạp tiền vào tài khoản của mình để bắt đầu giao dịch và nhận bonus</p>
                                    <p>
                                        XM hỗ trợ nhiều phương thức nạp tiền khác nhau như: Chuyển khoản, Thẻ tín dụng/
                                        ghi nợ, Ví điện tử...
                                    </p>
                                </div>
                            </div>
                            <div className={cx('content')}>
                                <b>
                                    <strong>B7</strong>: Bắt đầu giao dịch
                                </b>
                                <div className={cx('sub-title-way')}>
                                    <p>Sau khi nạp tiền thành công, bonus sẽ tự động được cộng vào tài khoản</p>
                                    <p>
                                        Bạn có thể bắt đầu giao dịch forex trên XM và sử dụng bonus để tăng thêm lợi
                                        nhuận của mình
                                    </p>
                                    <p>
                                        Bạn có thể tham khảo tài liệu hướng dẫn giao dịch của XM hoặc tham gia các khoá
                                        học forex miễn phí do XM cung cấp
                                    </p>
                                    <p>Bonus được cộng vào tài khoản XM sau khi nạp tiền thành công</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start open account */}
            <OpenAccount />
            {/* Identify */}
            <Identify />

            {/* <div style={{ height: '1000px' }}></div> */}
        </div>
    );
}

export default XM;
