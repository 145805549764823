import classNames from 'classnames/bind';
import styles from './Banner.module.scss';

import Image from '~/components/Image';
import images from '~/assets/images';

const cx = classNames.bind(styles);

function Banner({
    img,
}) {
    
    return (
        <div className={cx('welcome-container')}>
            <div className={cx('container-content')}>
                <div className={cx('content')}>
                    <h1 className={cx('content-header')}>
                        <Image className={cx('logo')} src={images.logoTho} />
                        TRADING
                    </h1>
                    <h2 className={cx('content-description')}>
                        Backcom tự động với tất cả các cặp giao dịch tại Exness, XM, Binance
                    </h2>
                    <div className={cx('content-text')}>
                        <p>
                            Hoàn <i>100%</i> phí giao dịch Exness, XM, Binance <br />
                            Lên tới <i>16$/lot</i> <br />
                        </p>
                        <p>Miễn phí qua đêm Xauusd Exness</p>
                        <p>
                            Bonus ngay <i>100%</i> tại XM
                        </p>
                    </div>
                </div>
                <Image className={cx('image-content')} src={img} />
            </div>
        </div>
    );
}

Banner.prototype = {};

export default Banner;
