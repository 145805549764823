import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-scroll';
import styles from './ButtonLink.module.scss';

const cx = classNames.bind(styles);

function ButtonLink({
    to,
    href,
    small = false,
    large = false,
    children,
    className,
    leftIcon,
    rightIcon,
    onClick,
    ...passProps
}) {
    // let Comp = 'button';
    const props = {
        onClick,
        ...passProps,
    };

    // // Remove event listener when btn is disabled
    // if (disabled) {
    //     Object.keys(props).forEach((key) => {
    //         if (key.startsWith('on') && typeof props[key] === 'function') {
    //             delete props[key];
    //         }
    //     });
    // }

    // if (to) {
    //     props.to = to;
    //     Comp = Link;
    // } else if (href) {
    //     props.href = href;
    //     Comp = 'a';
    // }

    const classes = cx('btn-open-account', {
        [className]: className,
        // primary,
        // outline,
        // text,
        // disabled,
        // rounded,
        small,
        large,
    });

    return (
        // <Comp >
        //     {leftIcon && <span className={cx('icon')}>{leftIcon}</span>}
        //     <span className={cx('title')}>{children}</span>
        //     {rightIcon && <span className={cx('icon')}>{rightIcon}</span>}
        // </Comp>
        <Link
            className={classes} {...props}
            activeClass="active"
            to={to}
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
        >
            {children}
        </Link>
    );
}

ButtonLink.propTypes = {
    to: PropTypes.string,
    href: PropTypes.string,
    primary: PropTypes.bool,
    outline: PropTypes.bool,
    text: PropTypes.bool,
    rounded: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    large: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    onClick: PropTypes.func,
};

export default ButtonLink;
